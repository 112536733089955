import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { TextbookModel } from "@/models";
import { Common } from "../Common";

export class TextbookForm {
  @IsDefined() @IsNotEmpty({ message: "必填" }) grade = "";
  @IsDefined() @IsNotEmpty({ message: "必填" }) category = "1";
  @IsDefined() @IsNotEmpty({ message: "必填" }) level = "";
  @IsDefined() @IsNotEmpty({ message: "必填" }) unit = "";
  @IsDefined() @IsNotEmpty({ message: "必填" }) pic = "";
  @IsDefined() @IsNotEmpty({ message: "必填" }) pdf = "";
  @IsDefined() @IsNotEmpty({ message: "必填" }) active = "";
  tbid = "";
}

type response = { [key: string]: unknown };

@Component<AdminTextbookEditController>({
  components: {
    SideNav,
    Lightbox
  }
})
export default class AdminTextbookEditController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private textbookForm = new TextbookForm();
  private token = window.localStorage.getItem("adminToken") as string;
  private gradeList: object = {};
  private levelList: object = {};
  private unitList: response = {};

  private errorMsg = "";
  private openLB = false;
  private errNo!: number;

  private errorMsgMap: { [key: string]: string } = {
    grade: "",
    category: "",
    level: "",
    unit: "",
    pic: "",
    pdf: "",
    active: ""
  };

  public async created() {
    const item = {
      tbid: this.$route.query.tbid,
      token: this.token
    };
    const editData = (await TextbookModel.get(item)) as response;
    this.assignDataToForm(editData);
    const grade = this.textbookForm.grade;
    const level = this.textbookForm.level;
    this.gradeList = await Common.getGrade();
    this.levelList = await Common.getLevel(grade);
    for (let i = 1; i <= 25; i++) {
      this.unitList[i] = { unit: "Unit-" + i };
    }
    this.unitList[26] = { unit: "Midterm Exam" };
    this.unitList[27] = { unit: "Final Exam" };
    this.unitList[28] = { unit: 'VIDEO Q' };
    this.unitList[29] = { unit: 'WORKSHOP' };
  }

  private assignDataToForm(data: response) {
    Common.checkToken(data.ERR_CODE as number);

    const item = data.info as { [key: string]: string };
    this.textbookForm.tbid = item.tbid;
    this.textbookForm.grade = item.grade;
    this.textbookForm.category = item.category;
    this.textbookForm.level = item.level;
    this.textbookForm.unit = item.unit;
    this.textbookForm.pic = item.pic;
    this.textbookForm.pdf = item.pdf;
    this.textbookForm.active = item.active;
  }

  private async getLevel() {
    this.textbookForm.level = "";
    this.textbookForm.unit = "";
    const grade = this.textbookForm.grade;
    this.levelList = await Common.getLevel(grade);
    this.unitList = {};
  }

  private async getUnit() {
    this.textbookForm.unit = "";
    for (let i = 1; i <= 25; i++) {
      this.unitList[i] = { unit: "Unit-" + i };
    }
    this.unitList[26] = { unit: "Midterm Exam" };
    this.unitList[27] = { unit: "Final Exam" };
    this.unitList[28] = { unit: 'VIDEO Q' };
    this.unitList[29] = { unit: 'WORKSHOP' };
  }

  private changePic(event: any) {
    const file = event.target.files[0];

    if (file) {
      //若有上傳圖片
      /* 限制檔案上傳型別 */
      const suffixName = file.name.substring(file.name.lastIndexOf(".") + 1); // 得到檔案字尾名
      if (
        suffixName !== "jpg" &&
        suffixName !== "png" &&
        suffixName !== "jpeg"
      ) {
        this.errorMsg = "上傳檔案只能是 jpg、png、jpeg 格式！";
        this.openLB = true;
        return;
      }
      /* 限制檔案上傳大小 */
      const isLimitSize = file.size / 1024 / 1024 < 2;
      if (!isLimitSize) {
        this.errorMsg = "上傳檔案不能超過 2MB！";
        this.openLB = true;
        return;
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.textbookForm.pic = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  private async changePdf(event: any) {
    const file = event.target.files[0];

    if (file) {
      /* 限制檔案上傳型別 */
      const suffixName = file.name.substring(file.name.lastIndexOf(".") + 1); // 得到檔案字尾名
      if (suffixName !== "pdf") {
        this.errorMsg = "上傳檔案只能是 pdf 格式！";
        this.openLB = true;
        return;
      }
      /* 限制檔案上傳大小 */
      const isLimitSize = file.size / 1024 / 1024 < 5;
      if (!isLimitSize) {
        this.errorMsg = "上傳檔案不能超過 5MB！";
        this.openLB = true;
        return;
      }
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.textbookForm.pdf = file.name;
      };
      reader.readAsDataURL(file);
    }
  }

  private validateAndSubmit() {
    validate(this.textbookForm, { skipMissingProperties: true }).then(
      async result => {
        if (result.length !== 0) {
          this.errorMsgMap = {
            //先清空error message
            grade: "",
            category: "",
            level: "",
            unit: "",
            pic: "",
            pdf: "",
            active: ""
          };
          result.forEach((item, index) => {
            const error = item.constraints as {
              [key: string]: string;
            };
            Object.keys(error).forEach(key => {
              this.errorMsgMap[item.property] = error[key]; //放入error message
            });
          });
          return;
        }

        if (!this.doubleClick) {
          this.doubleClick = true;

          const formobj: HTMLFormElement = document.getElementById(
            "textbookForm"
          ) as HTMLFormElement;
          const data = new FormData(formobj); //創建空的Formdata object

          data.append("tbid", this.textbookForm.tbid);
          data.append("token", this.token);

          this.errNo = await TextbookModel.edit(data);
          if (this.errNo === ErrorCode.InvalidToken) {
            Common.logout();
            return;
          }
          this.openLB = true;
          this.doubleClick = false;
          this.errorMsg = ErrorMessage[this.errNo];
        }
      }
    );
  }

  private closeLB() {
    this.openLB = false;
    if (this.errNo === ErrorCode.Success) {
      this.$router.push("/admin/textbook");
    }
  }
}
